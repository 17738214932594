.circularprocess {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  margin: 15px 15px 0px 15px;
}

@media screen and (max-width: 600px) {
  .btn {
    font-size: 0.7rem;
  }
}

.text-success {
  color: #00c624 !important;
}

.btn-success {
  color: #fff;
  background-color: #09a627;
  border-color: #09a627;
  z-index: 1;
}

.text-danger {
  color: #e70000 !important;
}

.btn-danger {
  color: #fff;
  background-color: #e70000;
  border-color: #e70000;
  z-index: 1;
}

.text-danger-light {
  color: #ff6363 !important;
}

.btn-danger-light {
  color: #fff;
  background-color: #ff6363;
  border-color: #ff6363;
}

.btn-danger-light:hover {
  color: #fff;
  background-color: #d64848;
  border-color: #d64848;
}

.thank-you__items-color-blue {
  color: #00599a;
}

.thank-you__items-color-red {
  color: #ff6363;
}

.confirmed__items-color-blue {
  color: #00599a;
}

.confirmed__items-color-red {
  color: #ff6363;
}
